// utils
import { useMemo } from "react";
import {
  IGoogleAuth,
  IIntegrationReport,
  IIntegrationStatus,
} from "src/types/integration";
import axios, { endpoints, fetcher } from "src/utils/axios";
import useSWR from "swr";

// ----------------------------------------------------------------------

export async function processGoogleCode(channelId: string, code: string) {
  return await axios.post(endpoints.integration.processGoogleCode, {
    channelId,
    code,
  });
}

// ----------------------------------------------------------------------

export function useGetAuthUrl() {
  const URL = endpoints.integration.authUrl;

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, {
    keepPreviousData: false,
  });

  const memoizedValue = useMemo(
    () => ({
      auth: data as IGoogleAuth,
      authLoading: isLoading,
      authError: error,
      authValidating: isValidating,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

// ----------------------------------------------------------------------

export function useGetIntegrationStatus(channelId: string) {
  const URL = channelId
    ? [endpoints.integration.status, { params: { channelId } }]
    : null;

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, {
    keepPreviousData: false,
  });

  const memoizedValue = useMemo(
    () => ({
      status: data as IIntegrationStatus,
      statusLoading: isLoading,
      statusError: error,
      statusValidating: isValidating,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

// ----------------------------------------------------------------------

export async function syncWithGoogle() {
  return await axios.post(endpoints.integration.syncWithGoogle);
}

// ----------------------------------------------------------------------

export function useGetIntegrationReports() {
  const URL = endpoints.integration.resume;

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, {
    keepPreviousData: true,
  });

  const memoizedValue = useMemo(
    () => ({
      report: data as IIntegrationReport,
      reportLoading: isLoading,
      reportError: error,
      reportValidating: isValidating,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

// ----------------------------------------------------------------------

export async function revokeAccess(channelId: string) {
  return await axios.post(endpoints.integration.revoke, {
    channelId,
  });
}
