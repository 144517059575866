import merge from "lodash/merge";
import {
  enUS as enUSAdapter,
  de as deDEAdapter,
  fr as frFRAdapter,
  it as itITAdapter,
  es as esESAdapter,
  ptBR as ptBRAdapter,
} from "date-fns/locale";
// core
import {
  enUS as enUSCore,
  deDE as deDECore,
  frFR as frFRCore,
  itIT as itITCore,
  esES as esESCore,
  ptBR as ptBRCore,
} from "@mui/material/locale";
// date-pickers
import {
  enUS as enUSDate,
  deDE as deDEDate,
  frFR as frFRDate,
  itIT as itITDate,
  esES as esESDate,
  ptBR as ptBRDate,
} from "@mui/x-date-pickers/locales";
// data-grid
import {
  enUS as enUSDataGrid,
  deDE as deDEDataGrid,
  frFR as frFRDataGrid,
  itIT as itITDataGrid,
  esES as esESDataGrid,
  ptBR as ptBRDataGrid,
} from "@mui/x-data-grid";

export const allLangs = [
  {
    country: "United Kingdom",
    label: "btn_english",
    value: "en",
    systemValue: merge(enUSDate, enUSDataGrid, enUSCore),
    adapterLocale: enUSAdapter,
    icon: "flagpack:gb-nir",
    locale: "en-GB",
  },
  {
    country: "Deutschland",
    label: "btn_german",
    value: "de",
    systemValue: merge(deDEDate, deDEDataGrid, deDECore),
    adapterLocale: deDEAdapter,
    icon: "flagpack:de",
    locale: "de",
  },
  {
    country: "France",
    label: "btn_french",
    value: "fr",
    systemValue: merge(frFRDate, frFRDataGrid, frFRCore),
    adapterLocale: frFRAdapter,
    icon: "flagpack:fr",
    locale: "fr",
  },
  {
    country: "Italy",
    label: "btn_italian",
    value: "it",
    systemValue: merge(itITDate, itITDataGrid, itITCore),
    adapterLocale: itITAdapter,
    icon: "flagpack:it",
    locale: "it",
  },
  {
    country: "Spain",
    label: "btn_spanish",
    value: "es",
    systemValue: merge(esESDate, esESDataGrid, esESCore),
    adapterLocale: esESAdapter,
    icon: "flagpack:es",
    locale: "es",
  },
  {
    country: "Brazil",
    label: "btn_portuguese",
    value: "pt",
    systemValue: merge(ptBRDate, ptBRDataGrid, ptBRCore),
    adapterLocale: ptBRAdapter,
    icon: "flagpack:br",
    locale: "pt-BR",
  },
];

export const defaultLang = allLangs[0]; // English

// GET MORE COUNTRY FLAGS
// https://icon-sets.iconify.design/flagpack/
// https://www.dropbox.com/sh/nec1vwswr9lqbh9/AAB9ufC8iccxvtWi3rzZvndLa?dl=0
