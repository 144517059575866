import { Helmet } from "react-helmet-async";
import { useLocales } from "src/locales";
// sections
import AccountGoogleCallback from "src/sections/account/account-google-callback";

// ----------------------------------------------------------------------

export default function GoogleCallbackPage() {
  const { t } = useLocales();

  return (
    <>
      <Helmet>
        <title> Lhasa | {t("account_headline")}</title>
      </Helmet>

      <AccountGoogleCallback />
    </>
  );
}
