// utils
import axios, { endpoints, fetcher } from "src/utils/axios";
// types
import useSWR from "swr";
import { useMemo } from "react";
import { ICatalogSerchSkuResult } from "src/types/catalog";

// ----------------------------------------------------------------------

export function useSearchProducts(searchTerm: string) {
  const URL = searchTerm
    ? [endpoints.catalog.search, { params: { searchTerm } }]
    : null;

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, {
    keepPreviousData: true,
  });

  const memoizedValue = useMemo(
    () => ({
      catalogSkus: data as ICatalogSerchSkuResult[],
      catalogSkusLoading: isLoading,
      catalogSkusError: error,
      catalogSkusValidating: isValidating,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}
