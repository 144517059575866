import { useGetChannel } from "src/api/channel";
import { useSearchParams } from "react-router-dom";
import { SplashScreen } from "src/components/loading-screen";
import { useEffect } from "react";
import { processGoogleCode } from "src/api/integration";

// ----------------------------------------------------------------------

export default function AccountGoogleCallback() {
  const [searchParams] = useSearchParams();
  const { channel, channelLoading, channelError } = useGetChannel("Google");

  useEffect(() => {
    async function process() {
      const code = searchParams.get("code");

      if (channelError) {
        window.close();
        return;
      }

      if (channel && code && !channelLoading) {
        await processGoogleCode(channel.id as string, code);
        console.log("done");
        window.close();
      }
    }

    process();
  }, [channel, channelError, channelLoading, searchParams]);

  return (
    <>
      <SplashScreen />
    </>
  );
}
