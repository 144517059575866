// utils
import axios, { endpoints, fetcher } from "src/utils/axios";
// types
import { IAppUser, IAppUserResponse } from "src/types/appUser";
import useSWR from "swr";
import { useMemo } from "react";

// ----------------------------------------------------------------------

export async function createAppUser(newUser: IAppUser): Promise<IAppUserResponse> {
  return (await axios.post(endpoints.appUser.new, newUser)).data;
}

// ----------------------------------------------------------------------

export async function updateAppUser(appUser: any) {
  return await axios.put(endpoints.appUser.edit, appUser);
}

// ----------------------------------------------------------------------

export function useGetAppUser(appUserId: string) {
  const URL = appUserId ? `${endpoints.appUser.details}/${appUserId}` : null;

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, {
    keepPreviousData: true,
  });

  const memoizedValue = useMemo(
    () => ({
      appUser: data as IAppUserResponse,
      appUserLoading: isLoading,
      appUserError: error,
      appUserValidating: isValidating,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

// ----------------------------------------------------------------------

export async function verifyAppUser(appUser: any) {
  return await axios.put(endpoints.appUser.verify, appUser);
}
