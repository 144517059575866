// utils
import axios, { endpoints, fetcher } from "src/utils/axios";
// types
import useSWR, { mutate } from "swr";
import { useMemo } from "react";
import { IChannel, IChannelConnection } from "src/types/channel";

// ----------------------------------------------------------------------

function convertEmptyStringsToNull(channel: IChannel): IChannel {
  const updatedChannel: IChannel = { ...channel };

  (Object.keys(updatedChannel) as (keyof IChannel)[]).forEach(key => {
    if (typeof updatedChannel[key] === 'string' && updatedChannel[key] === '') {
      updatedChannel[key] = null as any; // Use type assertion here
    }
  });

  return updatedChannel;
}

export async function updateChannel(channel: IChannel) {
  const updatedChannel = convertEmptyStringsToNull(channel);
  return await axios.put(endpoints.channel.connectChannel, updatedChannel);
}

// ----------------------------------------------------------------------

export function useGetChannelById(id: string) {
  const URL = `${endpoints.channel.details}/${id}`;

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, {
    keepPreviousData: true,
  });

  const memoizedValue = useMemo(
    () => ({
      channel: data as IChannel,
      channelLoading: isLoading,
      channelError: error,
      channelValidating: isValidating,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

// ----------------------------------------------------------------------

export function useGetChannel(name: string) {
  const URL = name ? [endpoints.channel.details, { params: { name } }] : null;

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, {
    keepPreviousData: true,
  });

  const memoizedValue = useMemo(
    () => ({
      channel: data as IChannel,
      channelLoading: isLoading,
      channelError: error,
      channelValidating: isValidating,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

// ----------------------------------------------------------------------

export function useGetChannels() {
  const URL = endpoints.channel.list;

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);

  const memoizedValue = useMemo(
    () => ({
      channels: (data as IChannel[]) || [],
      channelsLoading: isLoading,
      channelsError: error,
      channelsValidating: isValidating,
      channelsEmpty: !isLoading && !data?.length,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

// ----------------------------------------------------------------------

export function useAnyChannelConnected() {
  const URL = [endpoints.channelconnection.details, { params: { } }];

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, {
    keepPreviousData: true,
  });

  const memoizedValue = useMemo(
    () => ({
      channelConnection: data as IChannelConnection,
      channelConnectionLoading: isLoading,
      channelConnectionError: error,
      channelConnectionValidating: isValidating,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

// ----------------------------------------------------------------------

export async function connectChannelOLD(channelId: string) {
  const URL = channelId
    ? `${endpoints.channel.connectChannel}?channelId=${channelId}`
    : "";

  try {
    const response = await axios.post(URL);

    mutate(endpoints.channel.list);
    mutate([endpoints.channel.details, channelId]);

    return response.data;
  } catch (response) {
    if (!response.success) {
      console.error("Erro 400: Bad Request");
      return response;
    }
    throw response;
  }
}

// ----------------------------------------------------------------------

export async function disconnectChannel(channel: any) {
  try {
    const response = await axios.put(endpoints.channel.disconnectChannel, channel);

    mutate(endpoints.channel.list);

    return response.data;
  } catch (response) {
    if (!response.success) {
      console.error("Erro 400: Bad Request");
      return response;
    }
    throw response;
  }
}

// ----------------------------------------------------------------------

export async function connectChannel(channel: any) {
  try {
    const connectChannel = await axios.put(endpoints.channel.connectChannel, channel);

    //mutate(endpoints.store.details);

    return connectChannel;
  } catch (response) {
    if (!response.success) {
      console.error("Erro 400: Bad Request");
      return response;
    }
    throw response;
  }
}