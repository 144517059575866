import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
// utils
import { localStorageGetItem } from 'src/utils/storage-available';
//
import { defaultLang } from './config-lang';
//
import translationEn from './langs/en.json';
import translationDe from './langs/de.json';
import translationFr from './langs/fr.json';
import translationIt from './langs/it.json';
import translationEs from './langs/es.json';
import translationPt from './langs/pt.json';

// ----------------------------------------------------------------------

const lng = localStorageGetItem('i18nextLng', defaultLang.value);

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: { translations: translationEn },
      de: { translations: translationDe },
      fr: { translations: translationFr },
      it: { translations: translationIt },
      es: { translations: translationEs },
      pt: { translations: translationPt },
    },
    lng,
    fallbackLng: lng,
    debug: false,
    ns: ['translations'],
    defaultNS: 'translations',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
