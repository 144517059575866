import { useState, memo, useCallback, useEffect } from "react";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
// @mui
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Stack from "@mui/material/Stack";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Dialog, { dialogClasses } from "@mui/material/Dialog";
// hooks
import { useBoolean } from "src/hooks/use-boolean";
import { useResponsive } from "src/hooks/use-responsive";
import { useEventListener } from "src/hooks/use-event-listener";
// components
import Label from "src/components/label";
import Iconify from "src/components/iconify";
import Scrollbar from "src/components/scrollbar";
import { useRouter } from "src/routes/hooks";
import SearchNotFound from "src/components/search-not-found";
//
import ResultItem from "./result-item";
import { useNavData } from "../../dashboard/config-navigation";
import { applyFilter, groupedData, getAllItems } from "./utils";
import { useSearchProducts } from "src/api/catalog";
import { useDebounce } from "src/hooks/use-debounce";
import { ICatalogSerchSkuResult } from "src/types/catalog";
import { paths } from "src/routes/paths";
import { SplashScreen } from "src/components/loading-screen";
import { useLocales } from "src/locales";

// ----------------------------------------------------------------------

function Searchbar() {
  const { t } = useLocales();
  
  const theme = useTheme();

  const router = useRouter();

  const search = useBoolean();

  const lgUp = useResponsive("up", "lg");

  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState<ICatalogSerchSkuResult[]>(
    []
  );

  const navData = useNavData();

  const handleClose = useCallback(() => {
    search.onFalse();
    setSearchQuery("");
  }, [search]);

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === "k" && event.metaKey) {
      search.onToggle();
      setSearchQuery("");
    }
  };

  useEventListener("keydown", handleKeyDown);

  const handleClick = useCallback(
    (selectedSku: ICatalogSerchSkuResult) => {
      router.push(paths.dashboard.product.new, {
        state: { sku: { ...selectedSku } },
      });
      handleClose();
    },
    [handleClose, router]
  );

  const debouncedSearchQuery = useDebounce(searchQuery, 800);

  const {
    catalogSkus,
    catalogSkusLoading,
    catalogSkusError,
    catalogSkusValidating,
  } = useSearchProducts(debouncedSearchQuery);

  useEffect(() => {
    if (debouncedSearchQuery.length > 2) {
      if (!catalogSkusLoading && !catalogSkusError) {
        setSearchResults(catalogSkus);
      }
    } else {
      setSearchResults([]);
    }
  }, [debouncedSearchQuery, catalogSkus, catalogSkusLoading, catalogSkusError]);

  const handleSearch = useCallback(
    async (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      setSearchQuery(event.target.value);
    },
    []
  );

  const notFound = searchQuery && !searchResults.length;

  const renderItems = () => {
    return searchResults.map((sku, index) => {
      const { title, coverUrl } = sku;
      const partsTitle = parse(title, match(title, searchQuery));

      return (
        <List key={index} disablePadding>
          <ResultItem
            path={[{ text: "", highlight: false }]}
            title={partsTitle}
            key={`${title}${index}`}
            coverUrl={coverUrl}
            onClickItem={() => handleClick(sku)}
          />
        </List>
      );
    });
  };

  const renderButton = (
    <Stack direction="row" alignItems="center">
      <IconButton onClick={search.onTrue}>
        <Iconify icon="eva:search-fill" />
      </IconButton>

      {lgUp && (
        <Label sx={{ px: 0.75, fontSize: 12, color: "text.secondary" }}>
          ⌘K
        </Label>
      )}
    </Stack>
  );

  return (
    <>
      {renderButton}

      <Dialog
        fullWidth
        maxWidth="sm"
        open={search.value}
        onClose={handleClose}
        transitionDuration={{
          enter: theme.transitions.duration.shortest,
          exit: 0,
        }}
        PaperProps={{
          sx: {
            mt: 15,
            overflow: "unset",
          },
        }}
        sx={{
          [`& .${dialogClasses.container}`]: {
            alignItems: "flex-start",
          },
        }}
      >
        <Box sx={{ p: 3, borderBottom: `solid 1px ${theme.palette.divider}` }}>
          <InputBase
            fullWidth
            autoFocus
            placeholder={t('search_bar_placeholder')}
            value={searchQuery}
            onChange={handleSearch}
            startAdornment={
              <InputAdornment position="start">
                <Iconify
                  icon="eva:search-fill"
                  width={24}
                  sx={{ color: "text.disabled" }}
                />
              </InputAdornment>
            }
            endAdornment={
              <Label sx={{ letterSpacing: 1, color: "text.secondary" }}>
                esc
              </Label>
            }
            inputProps={{
              sx: { typography: "h6" },
            }}
          />
        </Box>

        <Scrollbar sx={{ p: 3, pt: 2, height: 400 }}>
          {catalogSkusLoading ? (
            <SplashScreen />
          ) : notFound ? (
            <SearchNotFound query={searchQuery} sx={{ py: 10 }} />
          ) : (
            renderItems()
          )}
        </Scrollbar>
      </Dialog>
    </>
  );
}

export default memo(Searchbar);
