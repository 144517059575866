import { _mock } from 'src/_mock';

// TO GET THE USER FROM THE AUTHCONTEXT, YOU CAN USE

// CHANGE:
// import { useMockedUser } from 'src/hooks/use-mocked-user';
// const { user } = useMockedUser();

// TO:
// import { useAuthContext } from 'src/auth/hooks';
// const { user } = useAuthContext();

// ----------------------------------------------------------------------

export function useMockedUser() {
  const user = {
    id: '8864c717-587d-472a-929a-8e5f298024da-0',
    displayName: 'Max Muniz',
    email: 'max.muniz@lhasa.com.be',
    password: 'demo1234',
    photoURL: _mock.image.avatar(24),
    phoneNumber: '+55 (11) 98534-5090',
    country: 'Brasil',
    address: 'Rua Artur Araripe, 43/602, Gávea',
    state: 'Rio de Janeiro',
    city: 'Rio de Janeiro',
    zipCode: '22451-020',
    about: 'Serial entrepreneur with notable exits, remarkable capital gains, and a history of building dominant tech in e-commerce ERP/POS',
    role: 'admin',
    isPublic: true,
  };

  return { user };
}
