// utils
import axios, { endpoints, fetcher, getStoreId } from "src/utils/axios";
// types
import useSWR, { mutate } from "swr";
import { useMemo } from "react";
import { IStore } from "src/types/store";

// ----------------------------------------------------------------------

export async function updateStore(store: any) {
  try {
    const updatedStore = await axios.put(endpoints.store.edit, store);

    mutate(endpoints.store.details);

    return updatedStore;
  } catch (response) {
    if (!response.success) {
      console.error("Erro 400: Bad Request");
      return response;
    }
    throw response;
  }
}

// ----------------------------------------------------------------------

export function useGetStore() {
  const storeId = getStoreId();

  const { data, isLoading, error, isValidating } = useSWR(
    storeId ? endpoints.store.details : null,
    fetcher,
    {
      keepPreviousData: true,
    }
  );

  const memoizedValue = useMemo(
    () => ({
      store: data as IStore,
      storeLoading: isLoading,
      storeError: error,
      storeValidating: isValidating,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

// ----------------------------------------------------------------------

export async function verifyCNPJ(appUser: any) {
  return await axios.put(endpoints.store.verify, appUser);
}
