import axios, { AxiosRequestConfig } from "axios";
// config
import { HOST_API } from "src/config-global";

// ----------------------------------------------------------------------

let userStoreId: string = "";

const axiosInstance = axios.create({ baseURL: HOST_API });

axiosInstance.interceptors.response.use(
  (res) => res,
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || "Something went wrong"
    )
);

axiosInstance.interceptors.request.use(
  (config) => {
    if (userStoreId) {
      config.headers["x-lhasa-store-id"] = userStoreId;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export default axiosInstance;

// ----------------------------------------------------------------------

export const fetcher = async (args: string | [string, AxiosRequestConfig]) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.get(url, { ...config });

  return res.data;
};

export const setStoreId = (storeId: string) => {
  userStoreId = storeId;
};

export const getStoreId = () => {
  return userStoreId;
};

// ----------------------------------------------------------------------

export const endpoints = {
  chat: "/api/chat",
  kanban: "/api/kanban",
  calendar: "/api/calendar",
  auth: {
    me: "/api/auth/me",
    login: "/api/auth/login",
    register: "/api/auth/register",
  },
  store: {
    edit: "/api/store",
    details: "/api/store",
    testCertificate: "/api/store/test-certificate",
    verify: "/api/store/verify-cnpj",
  },
  order: {
    list: "/api/order",
    details: "/api/order",
    edit: "/api/order",
  },
  channel: {
    list: "/api/channel",
    edit: "/api/channel",
    details: "/api/channel",
    channelConnection: "/api/channel/channel-connection",
    connectChannel: "/api/channel/connect-channel",
    disconnectChannel: "/api/channel/disconnect-channel",
  },
  channelconnection: {
    details: "/api/channelconnection",
  },
  integration: {
    authUrl: "/api/integration/auth/google/url",
    processGoogleCode: "/api/integration/auth/google/callback",
    status: "/api/integration/auth/google/status",
    revoke: "/api/integration/auth/google/revoke",
    syncWithGoogle: "/api/integration/sync",
    resume: "/api/integration/resume",
  },
  appUser: {
    new: "/api/appuser",
    edit: "/api/appuser",
    details: "/api/appuser",
    verify: "/api/appuser/verify-appuser",
  },
  catalog: {
    search: "/api/catalog",
  },
  mail: {
    list: "/api/mail/list",
    details: "/api/mail/details",
    labels: "/api/mail/labels",
  },
  post: {
    list: "/api/post/list",
    details: "/api/post/details",
    latest: "/api/post/latest",
    search: "/api/post/search",
  },
  product: {
    list: "/api/sku",
    details: "/api/sku",
    search: "/api/sku",
    edit: "/api/sku",
    new: "/api/sku",
    delete: "/api/sku",
  },
  category: {
    list: "/api/category",
  },
  issuer: {
    testCertificate: "/api/issuer/test-certificate",
    registerStatus: "/api/issuer/register-status",
    createInvoice: "/api/issuer/create-invoice",
    downloadDanfe: "/api/issuer/download-danfe",
    downloadXML: "/api/issuer/download-xml",
    getNcmById: "/api/issuer/get-ncm-by-id",
    getNcmByCode: "/api/issuer/get-ncm-by-code",
  },
};
